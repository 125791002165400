<template>
  <div v-loading="isSubmitting">
    <el-form
      label-position="top"
      ref="configVariablesForm"
      label-width="100px"
      class="form-container"
      :model="configVariables"
      :rules="rules"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <page-header :title="__('Configure Variables')"></page-header>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        v-for="variable in variables"
        :key="variable.variable_id"
      >
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top"
            :prop="`${variable.variable_id}`"
          >
            <el-input
              v-model="configVariables[variable.variable_id]"
            ></el-input>
            <span slot="label"
              >{{ variable.variable_name }}
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content="`${variable.description}`"
              >
                <i class="el-icon-info el-input__icon" slot="reference"></i>
              </el-popover>
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <el-button
            v-if="!configureCreateOptions"
            class="submitBtn"
            @click="submitForm()"
            >{{ __("Go To Callflow") }}
          </el-button>
          <el-button v-else class="submitBtn" @click="handleConfigCreate"
            >{{ __("Configure Import Options") }}
          </el-button>
          <el-button class="cancelBtn" @click="$emit('cancel')"
            >{{ __("Cancel") }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
export default {
  name: "ConfigureVariables",
  components: { PageHeader },
  data() {
    return {
      configVariables: {},
      isSubmitting: false,
      rules: {}
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },
  props: {
    variables: {
      type: Array,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    filePath: {
      type: String,
      required: false,
      default: ""
    },
    createType: {
      type: String,
      required: true
    },
    template: {
      type: Object,
      required: false
    },
    configureCreateOptions: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    ...mapActions("tasks", {
      createTask: "createTask"
    }),

    handleConfigCreate() {
      this.$refs.configVariablesForm.validate(valid => {
        if (valid) {
          this.$set(
            this.task,
            "config_variables",
            _.map(this.configVariables, (variable_value, variable_id) => {
              return {
                variable_id,
                variable_value
              };
            })
          );
          this.$emit("config-create-options");
        } else {
          return false;
        }
      });
    },

    submitForm() {
      this.$refs.configVariablesForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          let isSync = this.createType === "blank";
          let task = _.cloneDeep(this.task);
          task.ac_id = this.selectedAccountId;
          task.create_type = this.createType;
          task.template_id = this.template ? this.template.template_id : -1;
          task.config_variables = _.map(
            this.configVariables,
            (variable_value, variable_id) => {
              return {
                variable_id,
                variable_value
              };
            }
          );
          task.file_path = this.filePath;
          this.createTask({ task, async: !isSync })
            .then(data => {
              this.isSubmitting = false;
              if (isSync) {
                this.$message({
                  type: "success",
                  message: __("Task added Successfully")
                });
              }
              this.$emit(
                "created",
                data.data.task_id,
                data.request_id,
                !data.request_id
              );
            })
            .catch(err => {
              console.error(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    variables: {
      immediate: true,
      handler: function(variables) {
        _.map(variables, variable => {
          this.rules[`${variable.variable_id}`] = [
            { required: true, message: `${variable.variable_name} is required` }
          ];
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
</style>
