<template>
  <div class="variable-row">
    <el-form :model="variable" ref="inputOutputForm" id="inputOutputForm">
      <el-row type="flex" :gutter="20">
        <el-col :span="6">
          <el-form-item
            prop="variable_name"
            :error="variable.variable_name_error"
          >
            <div
              :data-variable-text="__('Variable')"
              class="row-item variable-select"
            >
              <el-select
                v-model="variable.variable_name"
                filterable
                clearable
                :placeholder="__('Select Variable')"
                @change="onInputChangeHandler"
                ref="variableSelector"
              >
                <el-option
                  v-for="variable_option in singleValuedVariables"
                  :key="variable_option.variable_id"
                  :label="variable_option.variable_name"
                  :value="variable_option.variable_name"
                  :disabled="
                    checkDuplicateVariable(variable_option.variable_name)
                  "
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="17">
          <el-form-item prop="description" :error="variable.description_error">
            <div
              :data-description-text="__('Description')"
              class="row-item description-input"
            >
              <el-input
                v-model="variable.description"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
                @input="onInputChangeHandler"
                id="inputField"
              ></el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col
          :span="1"
          style="display:flex;justify-content:center;align-items:center"
        >
          <el-form-item prop="delete-icon">
            <span>
              <i
                class="el-icon-circle-close"
                @click="deleteVariable"
                id="deleteBtn"
              ></i>
            </span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "InputOutputVariableConfig",
  props: {
    variable_list: {
      required: true,
      type: Object
    },
    variable_type: {
      required: true,
      type: String
    },
    index: {
      required: true
    },
    selectedVariables: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters("variables", {
      variables: "variables",
      singleValuedVariables: "singleValuedVariables"
    }),
    variable() {
      return this.variable_list;
    }
  },
  methods: {
    onInputChangeHandler() {
      this.$emit("change", this.variable_type);
    },
    deleteVariable() {
      this.$emit("delete", this.variable_type);
    },
    checkDuplicateVariable(name) {
      return _.includes(this.selectedVariables, name);
    },
    validateVariable() {
      if (
        _.isEmpty(this.variable.variable_name) &&
        !_.isEmpty(this.variable.description)
      ) {
        this.$set(
          this.variable,
          "variable_name_error",
          "variable name is required"
        );
        this.$set(this.variable, "description_error", "");
      } else if (
        !_.isEmpty(this.variable.variable_name) &&
        _.isEmpty(this.variable.description)
      ) {
        this.$set(
          this.variable,
          "description_error",
          "description is required"
        );
        this.$set(this.variable, "variable_name_error", "");
      } else {
        this.$set(this.variable, "description_error", "");
        this.$set(this.variable, "variable_name_error", "");
      }
    }
  },
  watch: {
    variable: {
      deep: true,
      immediate: true,
      handler: function(value) {
        this.validateVariable();
        this.$emit("change", this.variable_type, value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.variable-row {
  margin-top: 20px;
  margin-bottom: 10px;
  &:hover {
    .el-icon-circle-close {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
    }
  }
}

.el-icon-circle-close {
  transition: none;
  opacity: 0;
}

.variable-row:not(:first-child) {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.variable-select,
.description-input,
.default-value-input {
  position: relative;
}

.variable-select::before {
  content: attr(data-variable-text);
  position: absolute;
  top: -30px;
  left: 0;
  color: #696969;
}

.description-input::before {
  content: attr(data-description-text);
  position: absolute;
  top: -30px;
  left: 0;
  color: #696969;
}

.default-value-input::before {
  content: attr(data-default-value-text);
  position: absolute;
  top: -30px;
  left: 0;
  color: #696969;
}

.el-form .el-form-item {
  margin-bottom: 10px !important;
}
</style>
