<template>
  <div v-if="formAction" style="padding: 20px;">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentFormInfo.template_name }}</div>
    </div>

    <div>
      <div class="titles">{{ __("Description") }}</div>
      <div class="details">{{ contentFormInfo.template_description }}</div>
    </div>
    <div class="titles" v-if="contentFormInfo.tags">{{ __("Tags") }}</div>
    <div
      style="display: flex;margin-top: 10px;flex-wrap: wrap"
      v-if="contentFormInfo.tags"
    >
      <span
        v-for="(tag, index) in contentFormInfo.tags.split(',')"
        :key="`${tag}_${index}`"
        class="tag"
      >
        {{ tag }}</span
      >
    </div>
    <div>
      <div class="titles">{{ __("Publisher") }}</div>
      <div class="details">{{ contentFormInfo.publisher }}</div>
    </div>
    <div>
      <div class="titles">{{ __("Date Published") }}</div>
      <template>
        <el-tooltip
          :content="formattedDateTime(contentFormInfo.published_at)"
          placement="top"
        >
          <span class="details">
            {{ publishedAt(contentFormInfo.published_at) }}
          </span>
        </el-tooltip>
      </template>
    </div>
    <div>
      <div class="titles">{{ __("Import count") }}</div>
      <div class="details">{{ contentFormInfo.import_count }}</div>
    </div>
    <div>
      <div class="titles">User</div>
      <div class="details">{{ templateUserEmail(contentFormInfo) }}</div>
    </div>
    <div>
      <div class="titles">Account</div>
      <div class="details">{{ templateAccountName(contentFormInfo) }}</div>
    </div>
    <el-button
      plain
      @click="handleEditBtnClick"
      class="editBtn"
      v-if="
        (contentFormInfo.is_system && this.$auth.check('system')) ||
          (!contentFormInfo.is_system && userHasSPScopeToAc)
      "
      :disabled="!this.canWrite()"
      >{{ __("Edit") }}</el-button
    >
    <el-button
      plain
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      :disabled="!this.canWrite()"
      v-if="
        (contentFormInfo.is_system && this.$auth.check('system')) ||
          (!contentFormInfo.is_system && userHasSPScopeToAc)
      "
      >{{ __("Delete") }}
    </el-button>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      custom-class="editContentItemModal"
      destroy-on-close
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      fullscreen
    >
      <el-form
        label-position="top"
        ref="editTaskLibrary"
        label-width="100px"
        class="form-container"
        :rules="rules"
        :model="contentForm"
      >
        <el-row type="flex" style="padding-top: 34px">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Edit Template')"></page-header>
          </el-col>
        </el-row>
        <el-row type="flex" style="padding-bottom: 30px">
          <el-col
            :span="12"
            :offset="6"
            style="display: flex;align-items: center"
          >
            <TaskImageLibrary
              :image-path-prop="contentForm.image_path"
              @image-selected="handleImageSelected"
            ></TaskImageLibrary>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Template Name')"
              prop="template_name"
            >
              <el-input v-model="contentForm.template_name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item class="form_label_top" :label="__('Tags')">
              <TagsInput
                :tags="contentForm.tags"
                @tag-update="handleTagUpdate"
                autocomplete
                :autocomplete-list="autocompleteTags"
              ></TagsInput>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Template Description')"
              prop="template_description"
            >
              <el-input
                type="textarea"
                v-model="contentForm.template_description"
                :autosize="{ minRows: 4, maxRows: 6 }"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top in-variables"
              :label="__('Input Variables')"
              prop="input_variables"
            >
              <input-output-variable-config
                v-for="(input_variable, index) in contentForm.input_variables"
                :key="index"
                :index="index"
                :variable_list="input_variable"
                :variable_type="'input'"
                :selectedVariables="selectedInputVariables(contentForm)"
                @change="inputOutputVariableChangeHandler($event, contentForm)"
                @delete="
                  deleteInputOutputVariableHandler($event, index, contentForm)
                "
                ref="inputChildComponent"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top in-variables"
              :label="__('Output Variables')"
              prop="output_variables"
            >
              <input-output-variable-config
                v-for="(output_variable, index) in contentForm.output_variables"
                :key="index"
                :index="index"
                :variable_list="output_variable"
                :variable_type="'output'"
                :selectedVariables="selectedOutputVariables(contentForm)"
                @change="inputOutputVariableChangeHandler($event, contentForm)"
                @delete="
                  deleteInputOutputVariableHandler($event, index, contentForm)
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="this.$auth.check('system')">
          <el-col :span="12" :offset="6">
            <el-form-item class="form_label_top" :label="__('Is System')">
              <el-checkbox
                :value="!!contentForm.is_system"
                @input="handleCheckboxInput"
              ></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-button
                class="submitBtn"
                @click="submitForm()"
                :loading="isSubmitting"
                >{{ __("Save Changes") }}
              </el-button>
              <el-button class="cancelBtn" @click="handleCancel"
                >{{ __("Cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { formatTimeStampAsCalendar } from "@/utils/time";
import moment from "moment-timezone";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import TaskImageLibrary from "@/views/build/tasks/create-task/components/TaskImageLibrary";
import TagsInput from "@/views/build/tasks/create-task/components/TagsInput";
import { EventBus } from "@/EventBus";
import { templateNameExists } from "@/utils/formValidationRules";
import InputOutputVariableConfig from "@/views/build/tasks/create-task/components/TaskExport/ContentConfigure/InputOutputVariableConfig.vue";
import inputOutputVariablesMixin from "@/views/build/tasks/create-task/components/TaskExport/InputOutputVariablesMixin.vue";

export default {
  mixins: [BaseContent, BaseContentInfoPanel, inputOutputVariablesMixin],
  components: {
    PageHeader,
    TaskImageLibrary,
    TagsInput,
    InputOutputVariableConfig
  },
  data() {
    const validateTemplateName = async (rule, value, callback) => {
      let hasError = await templateNameExists(
        rule,
        value,
        this.contentFormInfo,
        true
      );
      if (hasError) {
        callback(__("Task name already exists"));
      } else {
        callback();
      }
    };
    return {
      isSubmitting: false,
      userTimezone:
        this.$auth.user().default_timezone === ""
          ? "UTC"
          : this.$auth.user().default_timezone,
      autocompleteTags: [
        { value: "Security" },
        { value: "Smart routing" },
        { value: "Surveys" },
        { value: "Hospitality" },
        { value: "Retail" },
        { value: "Marketing" }
      ],
      rules: {
        template_name: [
          {
            required: true,
            message: __("Template name cannot be empty"),
            trigger: "blur"
          },
          {
            max: 200,
            message: __("Length should be no more than 200 characters"),
            trigger: "blur"
          },
          {
            validator: validateTemplateName,
            trigger: "blur"
          }
        ],
        template_description: [
          {
            required: true,
            message: __("Template description cannot be empty"),
            trigger: "blur"
          },
          {
            max: 2000,
            message: __("Length should be no more than 2000 characters"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("app", {
      userHasSPScopeToAc: "userHasSPScopeToAc"
    }),
    publishedAt() {
      return publishedAt => {
        return formatTimeStampAsCalendar(publishedAt, this.userTimezone);
      };
    },
    templateUserEmail() {
      return templateUserEmail => {
        if (templateUserEmail.user) {
          if (
            Object.prototype.hasOwnProperty.call(
              templateUserEmail.user,
              "email"
            )
          ) {
            return templateUserEmail.user.email;
          }
          // if (templateUserEmail.user.hasOwnProperty("email")) {
          //   return templateUserEmail.user.email;
          // }
        }
        return " ";
      };
    },
    templateAccountName() {
      return templateAccountName => {
        if (templateAccountName.account) {
          if (
            Object.prototype.hasOwnProperty.call(
              templateAccountName.account,
              "ac_name"
            )
          ) {
            return templateAccountName.account.ac_name;
          }
        }
        return " ";
      };
    }
  },
  methods: {
    ...mapActions("tasklibrary", {
      updateTemplate: "updateTemplate",
      deleteContentMethod: "deleteTemplate",
      undoDeleteContent: "undoDeleteTemplate"
    }),
    handleTagUpdate(tags) {
      this.contentForm.tags = tags;
    },
    handleCheckboxInput(val) {
      this.contentForm.is_system = val;
    },

    handleImageSelected(imagePath) {
      this.contentForm.image_path = imagePath;
    },
    /**
     * Convert timestamp to human-readable date format with timezone
     *
     * @param {string} timestamp
     * @public
     */
    formattedDateTime(timestamp) {
      return moment
        .unix(timestamp)
        .tz(this.userTimezone)
        .format("MMMM Do, YYYY, HH:mm:ss A Z");
    },
    submitForm() {
      this.$refs["editTaskLibrary"].validate(valid => {
        if (valid && !this.checkVariableError(this.contentForm)) {
          this.isSubmitting = true;
          let template = _.cloneDeep(this.contentForm);
          this.cleanUpVariables(template);
          this.updateTemplate(template)
            .then(() => {
              this.isSubmitting = false;
              this.$message({
                type: "success",
                message: __("Template updated Successfully")
              });
              this.handleCancel();
              EventBus.$emit("list-changed");
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          console.log("error submit");
          return false;
        }
      });
    }
  },
  watch: {
    contentForm: {
      deep: true,
      immediate: true,
      handler: function() {
        this.initialiseInputVariables(this.contentForm);
        this.initialiseOutputVariables(this.contentForm);
      }
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";

.tag {
  background: #f5f5f5;
  padding: 5px;
  color: #a0a8b5;
  margin: 2px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  border: #a0a8b5 1px solid;
}

.form_label_top.in-variables {
  .variable-row:not(:first-child) {
    ::v-deep .row-item::before {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
