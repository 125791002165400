<!--suppress ALL -->
<template>
  <div v-if="formAction" style="padding: 20px">
    <TaskInfoPanel
      :task-info="contentFormInfo"
      @command="handleWorkflowAction"
      @edit="handleEditBtnClick"
      :languages="languages"
      v-if="showInfoPanel"
    ></TaskInfoPanel>
    <el-dialog
      v-if="formAction === 'extend'"
      :visible.sync="showTaskExtensionModal"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style=" max-height:calc(100vh - 34px)">
          <component
            :task="clone(contentForm)"
            :is="component"
            @cancel="handleCancel"
          ></component>
        </div>
      </el-scrollbar>
    </el-dialog>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
      show-close
      @close="clearContextAndHandleCancel"
    >
      <div
        style="position: absolute;left: 10px;top: 10px;z-index: 1000;"
        v-show="currentCreateStep > 1 && id === -1"
      >
        <el-button
          icon="el-icon-arrow-left"
          plain
          text
          class="backBtn"
          @click="handleBackBtnClick"
          >{{ __("Back") }}
        </el-button>
      </div>
      <div v-loading="taskCreating" :element-loading-text="getLoadingText">
        <transition :name="transitionName">
          <CreateTaskOptions
            v-show="currentCreateStep === 1 && id === -1"
            :id="id"
            @create-blank-task="handleCreateFromBlank"
            @create-from-template="handleCreateFromTemplate"
            @on-success="handleImportSuccess"
            :clear-files="clearFiles"
            style="margin-top: 34px;width: 100%"
          ></CreateTaskOptions>
        </transition>
        <transition :name="transitionName">
          <CreateOrEditForm
            :task="contentForm"
            :id="id"
            @cancel="clearContextAndHandleCancel()"
            @created="handleTaskCreation"
            :task-info="contentFormInfo"
            v-show="currentCreateStep === 2 || id !== -1"
            style="margin-top: 34px; width: 100%"
            :template="selectedTemplate"
            :create-type="createType"
            :config-variables="configureVariables"
            @config-variables="handleConfigureVariables"
            :configure-create-options="configureImportOptions"
            @config-create-options="handleConfigImportOptions"
            :file-path="filePath"
            :reset-input-fields="resetInputFields"
            :languages="languages"
          ></CreateOrEditForm>
        </transition>
        <transition :name="transitionName">
          <ConfigureVariables
            :variables="configVariablesArray"
            v-show="currentCreateStep === 3 && id === -1"
            style="margin-top: 34px; width: 100%"
            @created="handleTaskCreation"
            :configure-create-options="configureImportOptions"
            @config-create-options="handleConfigImportOptions"
            :task="contentForm"
            :create-type="createType"
            :file-path="filePath"
            @cancel="clearContextAndHandleCancel"
            :template="selectedTemplate"
          ></ConfigureVariables>
        </transition>
        <transition :name="transitionName">
          <ConfigureTaskCreateOptions
            :config-options="configImportOptions"
            :load-config="loadConfig"
            v-show="currentCreateStep === 4 && id === -1"
            style="margin-top: 34px; width: 100%"
            @created="handleTaskCreation"
            :task="contentForm"
            :create-type="createType"
            :file-path="filePath"
            @cancel="clearContextAndHandleCancel"
            :template="selectedTemplate"
          ></ConfigureTaskCreateOptions>
        </transition>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import TaskInfoPanel from "@/views/build/tasks/create-task/components/TaskInfoPanel";
import CreateOrEditForm from "@/views/build/tasks/create-task/components/createOrEditForm";
import CreateTaskOptions from "@/views/build/tasks/pages/CreateTaskOptions";
import TemplateDetails from "@/views/build/tasks/create-task/TemplateDetails";
import ConfigureVariables from "@/views/build/tasks/create-task/components/ConfigureVariables";
import _ from "lodash";
import { removeFiles } from "@/api/services";
import { mapActions, mapState, mapGetters } from "vuex";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import ConfigureTaskCreateOptions from "@/views/build/tasks/create-task/components/ConfigureTaskCreateOptions";
import TaskExport from "@/views/build/tasks/create-task/components/TaskExport/TaskExport";

export default {
  mixins: [BaseContent, BaseContentInfoPanel, TaskExport],
  name: "EditTask",
  components: {
    ConfigureTaskCreateOptions,
    TaskInfoPanel,
    CreateOrEditForm,
    CreateTaskOptions,
    TemplateDetails,
    ConfigureVariables
  },
  props: {
    showInfoPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    openEditModelProp: {
      required: false,
      default: false,
      type: Boolean
    },
    templateProp: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      selectedAction: "edit",
      currentCreateStep: 1,
      selectedTemplate: null,
      transitionName: "slide-left",
      taskCreated: false,
      taskCreating: false,
      createType: "blank",
      configureVariables: false,
      configVariablesArray: [],
      configImportOptions: {},
      configureImportOptions: false,
      loadConfig: {},
      filePath: "",
      clearFiles: false,
      resetInputFields: false,
      routedToTask: false
    };
  },
  // async mounted() {
  //   return Promise.all([
  //     this.getLanguages(),
  //     _.map(NODE_LOG_PROFILE, profile_type => {
  //       this.getNodeLoggingProfiles({
  //         fetch_all: 1,
  //         profile_type,
  //         fetch_minimum_details: 1
  //       });
  //     })
  //   ])
  //     .then(() => {})
  //     .catch(err => console.log(err));
  // },

  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages",
      deleteContentMethod: "deleteTask",
      undoDeleteContent: "undoDeleteTask"
    }),
    ...mapActions("nodeLoggingProfiles", {
      getNodeLoggingProfiles: "getNodeLoggingProfiles"
    }),
    handleConfigureVariables() {
      this.currentCreateStep = 3;
    },
    handleConfigImportOptions() {
      this.currentCreateStep = 4;
    },
    handleTaskCreation(task_id, request_id = null, proccedToTask = false) {
      this.taskCreating = true;
      if (!task_id) {
        console.error("handleTaskCreation cannot be called without task_id");
        this.$message.error(
          __("invalid arguments received to task creation handler")
        );
        return;
      }
      if (this.createType === "blank" || proccedToTask || !request_id) {
        this.navigateToCallflow(task_id);
      } else {
        this.listenForTaskImportExportUpdates(
          { request_id },
          task_id,
          this.onCreateTaskTaskImportExportCompletionEvent,
          this.onCreateTaskTaskImportExportErrorEvent,
          this.isCreateNewTaskTaskImportExportStatusCheckIntervalClearable
        );
      }
    },

    onCreateTaskTaskImportExportCompletionEvent(
      taskId,
      { request_info, request_id: requestId }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      if (!this.routedToTask) {
        this.navigateToCallflow(request_info.new_task_id);
      }
    },

    onCreateTaskTaskImportExportErrorEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task creation failed")
      });
      this.taskCreating = false;
    },

    isCreateNewTaskTaskImportExportStatusCheckIntervalClearable(event) {
      let { status } = event;
      return (
        this.routedToTask ||
        this.socketConnectionEstablished ||
        status === "completed" ||
        status === "error"
      );
    },

    navigateToCallflow(task_id) {
      this.loadingText = __("taking you to callflow");
      this.taskCreated = true;
      this.routedToTask = true;
      this.$router.push({
        name: "callflow",
        params: { task_id }
      });
    },

    handleImportSuccess(response) {
      this.clearFiles = false;
      this.$nextTick(() => {
        if (response.can_import) {
          this.currentCreateStep = 2;
          this.createType = "import";
          this.clearFiles = false;
          this.contentForm.task_type = response.task.task_type;
          this.contentForm.task_image = response.task.task_image;
          if (response.task.task_type === "voice") {
            this.contentForm.voice_task.data.beep_detection =
              response.task.voice_task.data.beep_detection;
            this.contentForm.voice_task.data.machine_detection =
              response.task.voice_task.data.machine_detection;
          }
          this.configVariablesArray = response.config_variables;
          this.configureVariables = this.configVariablesArray.length > 0;

          this.configImportOptions = _.get(
            response,
            "required_replacements",
            {}
          );
          this.configureImportOptions = true;
          this.loadConfig = response.load_config;

          this.filePath = response.path;
          this.resetInputFields = false;
          // this.fileUrl = response.
        } else {
          this.clearFiles = true;
          this.$message({
            type: "error",
            message: response.errors
          });
        }
      });
    },

    /**
     * custom back button handler overriding one in BaseContentInfoPanel
     * @returns {boolean}
     */
    backButtonHandler(to) {
      // Go to the previous step, or close the modal
      // console.log(to, from);
      // return false;
      if (to.name === "callflow" && this.taskCreated) {
        // this.handleCancel();
        this.taskCreated = false;
        return true;
      } else {
        if (this.openModal) {
          if (this.currentCreateStep === 1) {
            this.handleCancel();
          } else {
            this.handleBackBtnClick();
          }
          return false;
        }

        if (this.showTaskExtensionModal) {
          this.showTaskExtensionModal = false;
          return false;
        }
        return true;
      }
    },

    handleBackBtnClick() {
      if (this.currentCreateStep === 4 && !this.configureVariables) {
        this.currentCreateStep = 2;
        this.configureImportOptions = true;
      } else {
        this.currentCreateStep--;
        this.configureImportOptions = false;
      }

      if (this.currentCreateStep < 2) {
        this.clearFiles = true;
        this.selectedTemplate = null;
        this.resetInputFields = true;
        if (this.filePath) {
          removeFiles([this.filePath]);
        }
      }
    },
    handleCreateFromTemplate(template) {
      if (template.can_import) {
        this.currentCreateStep++;
        this.createType = "template";
        this.selectedTemplate = template;
        this.resetInputFields = false;
        this.contentForm.task_image = template.image_path;
        this.contentForm.task_type = template.task.task_type;
        if (template.task.task_type === "voice") {
          this.contentForm.voice_task.data.beep_detection =
            template.task.voice_task.data.beep_detection;
          this.contentForm.voice_task.data.machine_detection =
            template.task.voice_task.data.machine_detection;
        }
        this.configVariablesArray = template.config_variables;
        this.configureVariables = this.configVariablesArray.length > 0;

        this.configImportOptions = _.get(template, "required_replacements", {});
        this.configureImportOptions = true;

        this.loadConfig = template.load_config;
      } else {
        this.$message({
          type: "error",
          message: template.errors
        });
      }
    },
    handleCreateFromBlank() {
      if (this.canAddEditOrDelete()) {
        this.currentCreateStep++;
        this.createType = "blank";
        this.selectedTemplate = null;
        this.resetInputFields = false;
        this.contentForm.task_image = "task_image_placeholder.svg";
      } else {
        this.showPermissionError();
      }
    },
    clearContextAndHandleCancel() {
      this.currentCreateStep = 1;
      this.selectedTemplate = null;
      this.clearFiles = true;
      this.selectedTemplate = null;
      this.resetInputFields = true;
      this.configureImportOptions = false;
      if (this.filePath) {
        removeFiles([this.filePath]);
      }
      this.handleCancel();
    },
    handleWorkflowAction(command) {
      switch (command) {
        case "edit":
          this.selectedAction = null;
          this.handleEditBtnClick();
          break;
        case "TriggerWorkflow":
        case "ApiInfo":
          this.$emit("extend-item");
          break;
      }

      if (!["edit"].includes(command)) {
        this.selectedAction = command;
      }
    },
    clone(obj) {
      return _.cloneDeep(obj);
    },
    importComponent(path) {
      return () => import("@/views/build/tasks/pages/" + path);
    }
  },
  computed: {
    ...mapState("tasks", {
      languages: state => state.languages
    }),

    ...mapGetters("app", {
      spIdAcIdCombination: "selectedServiceProviderAccountCombination"
    }),

    component() {
      return this.importComponent(this.selectedAction);
    },

    showTaskExtensionModal: {
      get() {
        return this.formAction === "extend";
      },
      set() {
        this.handleCancel();
      }
    }
  },

  watch: {
    spIdAcIdCombination: {
      immediate: true,
      async handler() {
        await Promise.all([
          this.getLanguages(),
          _.map(NODE_LOG_PROFILE, profile_type => {
            this.getNodeLoggingProfiles({
              fetch_all: 1,
              profile_type,
              fetch_minimum_details: 1
            });
          })
        ])
          .then(() => {})
          .catch(() => {});
      }
    },
    currentCreateStep(newStep, oldStep) {
      if (newStep > oldStep) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
    openEditModelProp(val) {
      if (val) {
        this.handleOpenModal(val);
      }
    },
    templateProp: {
      immediate: true,
      handler: function(template) {
        if (template) {
          this.handleCreateFromTemplate(template);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.backBtn {
  border: none;
  font-size: 14px;
  font-weight: bold;

  ::v-deep i {
    font-size: 18px;
    font-weight: bolder;
  }

  ::v-deep span {
    margin-bottom: 6px;
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 700ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
}

.slide-right-leave-active {
  opacity: 0;
}

.slide-left-enter {
  opacity: 0;
}

.slide-left-leave-active {
  opacity: 0;
}
</style>
