<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/editPublishedTemplate";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "TaskLibraryIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("tasklibrary", {
      contents: state => state.templates,
      loading: state => state.loading,
      taskFilter: state => state.taskFilter
    })
  },

  methods: {
    ...mapActions("variables", {
      fetchVariables: "forceFetchVariables"
    }),

    initContent() {
      return {
        template_name: "",
        template_description: "",
        image_path: "",
        tags: "",
        is_system: 0,
        input_variables: [{ variable_name: "", description: "" }],
        output_variables: [{ variable_name: "", description: "" }]
      };
    }
  },

  async created() {
    await this.fetchVariables();
    this.contentType = "Task Template";
    this.primaryKey = "template_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
