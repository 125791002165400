<!--suppress ALL -->
<template>
  <div
    style="width: 100%;display: flex; flex-direction: column; align-items: center;"
    @click="handleClearSelection"
  >
    <div style="width: 100%; max-width: 1345px; position: relative">
      <page-header
        style="padding: 20px 30px;width: calc(100% - 45px)"
        :title="__('Task Library')"
        :navItems="navItems"
        @navItemChanged="handleNavItemChanged"
      ></page-header>
      <div style="width: 100%">
        <el-main>
          <el-form class="form-container">
            <PaginationToolbar
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              :page-sizes="[15, 30, 45, 60]"
              :page-size="15"
              style="padding: 0 10px"
            >
            </PaginationToolbar>
          </el-form>
          <el-scrollbar v-loading="loading" :native="true">
            <div class="contentCardsContainer">
              <el-row type="flex" justify="start" class="list-cards">
                <TaskTemplateCard
                  :task_template="template"
                  v-for="(template, index) in templates"
                  :class="{
                    selected: selectedItemIndex === index,
                    cardContainer: true
                  }"
                  :key="index"
                  @handleSelect="handleSelect(index, template)"
                  @handleEdit="preHandleEdit(index, template)"
                  :show-overlay="true"
                  @create-task-from-template="handleCreateTaskFromTemplate"
                ></TaskTemplateCard>
              </el-row>
            </div>
          </el-scrollbar>
        </el-main>
      </div>
      <create-or-edit
        :id="-1"
        :content="selectedContent"
        :form-action="`edit`"
        :open-edit-model-prop="openEditModal"
        :show-info-panel="false"
        v-if="openEditModal"
        :template-prop="template"
        @handleCancel="openEditModal = false"
      ></create-or-edit>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import TaskTemplateCard from "@/views/build/tasks/create-task/components/TaskTemplateCard";
import CreateOrEdit from "@/views/build/tasks/pages/createOrEdit";
import { currentTime } from "@/utils/time";
import TaskTypes from "@/constants/nav-items/task-types";

export default {
  name: "TaskLibraryList",
  mixins: [BaseContent],
  components: {
    CreateOrEdit,
    PaginationToolbar,
    PageHeader,
    TaskTemplateCard
  },
  data() {
    return {
      selectedContent: this.initContent(),
      sortByColumns: ["template_name", "template_id"],
      openEditModal: false,
      template: null,
      navItems: TaskTypes
    };
  },

  computed: {
    ...mapState("tasklibrary", {
      templates: state => state.templates,
      loading: state => state.loading,
      taskFilter: state => state.taskFilter
    }),

    ...mapGetters("app", {
      userHasSPScopeToAc: "userHasSPScopeToAc"
    }),

    activeNavItem() {
      return _.find(this.navItems, navItem => navItem.active);
    }
  },

  methods: {
    ...mapActions("tasklibrary", {
      contentAPI: "getTemplates",
      setTaskFilter: "setTaskFilter",
      decodeTemplate: "decodeTemplate"
    }),

    preHandleEdit(index, template) {
      if (
        (template.is_system && this.$auth.check("system")) ||
        (!template.is_system && this.userHasSPScopeToAc)
      ) {
        this.handleEdit(index, template);
      } else {
        this.$message.error(__("No permissions to edit this Template"));
        return true;
      }
    },

    initContent() {
      let now = currentTime();
      return {
        task_name: "",
        task_type: this.taskFilter,
        tags: "",
        task_image: "",
        is_favorite: 0,
        voice_task: {
          data: {
            tts_voice_id: "",
            beep_detection: false,
            machine_detection: false,
            send_to_operator: false,
            no_resource_operator_number: ""
          }
        },
        workflow_task: {
          data: {
            is_active: true,
            workflow_schedule: "Once",
            schedule_time_zone: "",
            start_on: now,
            is_repeat: 0,
            repeat_frequency: "01:00:00",
            number_of_executions: 0,
            passed_params: {
              data: []
            },
            schedule: {
              data: {
                starts_on: now,
                repeat_frequency: "Daily",
                repeat_interval: 1,
                repeat_in: "",
                end_type: "Never",
                end_type_value: "",
                repeat_summary: ""
              }
            }
          }
        },
        messaging_task: {
          data: {
            msg_session_timeout: 60
          }
        }
      };
    },
    async handleCreateTaskFromTemplate(template) {
      await this.decodeTemplate(template.template_id).then(data => {
        template = _.merge({}, template, data);
        this.template = template;
        this.selectedContent.task_type = template.task.task_type;
        this.openEditModal = true;
      });
    },

    assignTaskFilter() {
      let taskFilter;
      if (!this.taskFilter || !this.can(this.taskFilter)) {
        if (this.can("Voice")) {
          taskFilter = "voice";
        } else if (this.can("Messaging")) {
          taskFilter = "messaging";
        } else if (this.can("Workflow")) {
          taskFilter = "workflow";
        } else if (this.can("Chatbot")) {
          taskFilter = "chatbot";
        }
      } else {
        taskFilter = this.taskFilter;
      }
      if (taskFilter) {
        this.setTaskFilter(taskFilter);
        this.handleNavItemChanged({ label: _.upperFirst(taskFilter) });
      }
    },

    handleNavItemChanged(navItem, refreshList = true) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      this.setTaskFilter(navItem.label.toLowerCase());
      _.find(this.navItems, { label: navItem.label }).active = true;
      if (refreshList) {
        EventBus.$emit("list-changed");
      }
    }
  },

  created() {
    this.assignTaskFilter();
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
.list-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px;
}

.contentCardsContainer {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}
</style>
