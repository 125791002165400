<script>
import _ from "lodash";

const emptyVariable = () => ({
  variable_name: "",
  description: "",
  variable_name_error: "",
  description_error: ""
});

export default {
  name: "InputOutputVariablesMixin",
  computed: {
    selectedInputVariables() {
      return source => {
        let variable_copy = _.cloneDeep(source.input_variables);
        return _.map(variable_copy, function(variable) {
          return _.get(variable, "variable_name", "");
        });
      };
    },
    selectedOutputVariables() {
      return source => {
        let variable_copy = _.cloneDeep(source.output_variables);
        return _.map(variable_copy, function(variable) {
          return _.get(variable, "variable_name", "");
        });
      };
    }
  },
  methods: {
    inputOutputVariableChangeHandler(type, source) {
      let targetList =
        type === "input" ? "input_variables" : "output_variables";
      const lastIndex = source[targetList].length - 1;
      if (
        !_.isEmpty(source[targetList][lastIndex].variable_name) ||
        !_.isEmpty(source[targetList][lastIndex].description)
      ) {
        source[targetList].push(emptyVariable());
      }
    },
    deleteInputOutputVariableHandler(type, index, source) {
      let targetList =
        type === "input" ? "input_variables" : "output_variables";
      const lastIndex = source[targetList].length - 1;
      if (source[targetList].length > 1 && index !== lastIndex) {
        source[targetList].splice(index, 1);
      }
    },
    initialiseInputVariables(source) {
      if (source.input_variables.length === 0) {
        source.input_variables.push(emptyVariable());
      }
    },

    initialiseOutputVariables(source) {
      if (source.output_variables.length === 0) {
        source.output_variables.push(emptyVariable());
      }
    },
    cleanUpVariables(source) {
      _.remove(source.input_variables, input => {
        return _.isEmpty(input.variable_name) && _.isEmpty(input.description);
      });
      _.map(source.input_variables, input => {
        _.unset(input, "variable_name_error");
        _.unset(input, "description_error");
      });
      _.remove(source.output_variables, output => {
        return _.isEmpty(output.variable_name) && _.isEmpty(output.description);
      });
      _.map(source.output_variables, output => {
        _.unset(output, "variable_name_error");
        _.unset(output, "description_error");
      });
    },
    checkVariableError(source) {
      let input_error = _.filter(source.input_variables, input_variable => {
        return (
          !_.isEmpty(input_variable.variable_name_error) ||
          !_.isEmpty(input_variable.description_error)
        );
      });
      let output_error = _.filter(source.output_variables, output_variable => {
        return (
          !_.isEmpty(output_variable.variable_name_error) ||
          !_.isEmpty(output_variable.description_error)
        );
      });
      return !_.isEmpty(input_error) || !_.isEmpty(output_error);
    }
  }
};
</script>
